import { Layout, ViewTable } from "@/components";
import React from "react";
import columns from '@/page/sale_order/sale_order_list/sale_order_columns';
import WebApi from "@/web/api";
import dayjs from "dayjs";

export default class SaleOrderDetailReportPage extends React.PureComponent<RouterPropsType> {
  render() {
    let date = this.props.match?.params.date;
    let dateRange: [string, string] = [date, date];
    const customerId = this.props.match?.params.customerId;
    const customerName = this.props.match?.params.customerName;
    if (customerId) {
      date = date.split('-').map(i => dayjs(i, 'YYYYMMDD').format('YYYY.MM.DD')).join(' - ');
      dateRange = date.split('-').map(i => dayjs(i, 'YYYYMMDD').format('YYYY-MM-DD'))
    }
    return <Layout.Page>
      <Layout.Toolbar title={<span>
        {
          customerId &&
          <span style={{marginRight: 30}}>客户：{customerName}</span>
        }
        日期：{date}
      </span>}></Layout.Toolbar>
      <ViewTable
        flex1
        columns={columns}
        fetch={(data) => {
          return WebApi.sale_order_paging({...data, customerId, dateRange});
        }}
      />
    </Layout.Page>
  }
}